'use strict';
window.$ = window.jQuery = require('jquery');
require('jquery-ui');
require('jquery-validation');
var dialog = require('./dialog');

function displayMessage(data, button) {
    var status;
    if (data.success) {
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    if (data.success) {
    	$('.modalContent__content').html(data.msg);
        setTimeout(function () {
        	$(".ui-dialog .ui-dialog-titlebar-close").focus();
            dialog.close();
        }, 2000);
    } else {
    	$('.invalid-error').html(data.msg);
        setTimeout(function () {
        	$('.invalid-error').html('');
        }, 3000);
    }

}

function initializeEvents() {
    if (window.document.cookie.indexOf('emailsignupformpopup=true') == -1) {
        $.ajax({
            url: Urls.content + '?cid=email-signup-form-popup'
        }).done(function(html){
            if (!html) {
                return;
            }
            var popup = $(html);
            setTimeout(function () {
	            dialog.open({
	                html: popup,
	                dialogClass: 's-content',
	                options: {
	                	// height: auto, 
	                	width: 490, 
	                	resizable: false, 
	                	modal: true, 
	                	closeOnEscape: false,
	                    beforeClose: function( event, ui ) {
	                    	if ($(".ui-dialog .ui-dialog-titlebar-close").is(":focus")) { 
	                            window.document.cookie = 'emailsignupformpopup=true; path=/';
	                			return true;
	                		}
	                    	if ($(".ui-dialog .ui-dialog-titlebar-close").hasClass("ui-state-focus") ||
	                    			$(".ui-dialog .ui-dialog-titlebar-close").hasClass("ui-state-hover")) { 
	                            window.document.cookie = 'emailsignupformpopup=true; path=/';
	                			return true;
	                		}
	                    	return false;
	                	}
	                }
	            });
	            $('.subscribe-email').on('click', function (e) {
	                e.preventDefault();
	                var url = Urls.subscribe;
	                var button = $(this);
	                var emailId = $('input[name=email]').val();
	                if (!emailId || emailId == '') return;
	                $('.subscribe-email').attr('disabled', true);
	                $.ajax({
	                    url: url,
	                    type: 'post',
	                    dataType: 'json',
	                    data: {
	                        emailId: emailId,
	                        source: 'Modal'
	                    },
	                    success: function (data) {
	                    	$('.subscribe-email').attr('disabled', false);
	                        displayMessage(data, button);
	                    },
	                    error: function (err) {
	                    	$('.subscribe-email').attr('disabled', false);
	                        displayMessage(err, button);
	                    }
	                });
	            });
            }, 5000);
        });
    }
}
var SignUpFormPopup = {
    init: function () {
        initializeEvents();
    }
};
SignUpFormPopup.init();
module.exports = SignUpFormPopup;